<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="修改还款计划"
    >
      <div class="top">
        <div>融资总额度:{{ financeAmount }}</div>
        <div class="addbtn">
          <div class="addbutton" @click="add">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <base-table
        :columns="repaymentPlanConfig"
        :tableAttrs="{
          data: repaymentData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="remove(scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          />
        </template>
        <template slot="index" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <!-- 日期 -->
        <template slot="preRepayDate" slot-scope="scope">
          <el-date-picker
            v-model="scope.row.preRepayDate"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
            :editable='false'
            :picker-options="startOptions"
            @change='changeDate(scope.row.preRepayDate,scope.$index)'
          >
          </el-date-picker>
        </template>
        <!-- 还款金额 -->
        <template slot="preRepayAmount" slot-scope="scope">
          <amount-input
            placeholder="请输入还款金额"
            v-model="scope.row.preRepayAmount"
          />
        </template>
        <!-- 还款合同 -->
        <template slot="contractRepaymentArr" slot-scope="scope">
          <base-select
            v-model="scope.row.contractRepayment"
            :clearable="true"
            :multiple="true"
            :disabled="!scope.row.preRepayDate"
            :options="repaymentPlanContract"
            :selectedField="['keyId', 'contractName']"
            @change="changeContract(scope.row.contractRepayment, scope.$index)"
          ></base-select>
        </template>
        <!-- 还款条件 -->
        <template slot="repayCondition" slot-scope="scope">
          <base-input
            v-model="scope.row.repayCondition"
            maxlength='500'
            placeholder="请选择还款合同"
          ></base-input>
        </template>
      </base-table>
      <template slot="footer">
        <base-button label="提交" @click="submit" type="primary" />
        <base-button
          label="取消"
          @click="visibleState = false"
          type="default"
        />
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import { formatDate } from '@/utils/auth/common'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { repaymentPlanConfig } from '../utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
export default {
  components: {
    BaseDialog,
    BaseButton,
    BaseTable,
    IconButton,
    AmountInput,
    BaseSelect,
    BaseInput
  },
  props: {
    financeAmount: String,
    planShow: Boolean,
    repaymentData: Array,
    repaymentPlanContract: Array
  },
  data () {
    return {
      startOptions: {
        disabledDate (time) {
          return time.getTime() <= new Date('1999-12-31')
        }
      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    repaymentPlanConfig () {
      return repaymentPlanConfig(this)
    },
    visibleState: {
      get () {
        return this.planShow
      },
      set (val) {
        this.$emit('update:planShow', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

    remove (index) {
      this.repaymentData.splice(index, 1)
    },
    getTime (row) {
      if (row.isDate) {
        return row.preRepayDate
      } else {
        return formatDate(row.preRepayDate, 'YY/MM/DD')
      }
    },
    changeDate (data, index) {
      console.log(data, index)
      if (data) {
        this.$set(this.repaymentData[index], 'repayCondition', formatDate(
          this.repaymentData[index].preRepayDate,
          'YY/MM/DD'
        ) + '/')
      } else {
        this.$set(this.repaymentData[index], 'repayCondition', '')
      }
    },
    changeContract (data, index) {
      this.$forceUpdate()
      let value = ''
      const repaymentPlanContractArr = []
      data.forEach((elem) => {
        // elem 合同id
        this.repaymentPlanContract.forEach((item) => {
          if (item.keyId === elem) {
            if (repaymentPlanContractArr.length === 0) {
              repaymentPlanContractArr.push(
                this.repaymentData[index].isDate
                  ? this.repaymentData[index].preRepayDate +
                      '/' +
                      item.contractName
                  : formatDate(
                    this.repaymentData[index].preRepayDate,
                    'YY/MM/DD'
                  ) +
                      '/' +
                      item.contractName
              )
            } else {
              repaymentPlanContractArr.push(item.contractName)
            }
          }
        })
      })

      value = repaymentPlanContractArr.join(',')
      this.$set(this.repaymentData[index], 'repayContractId', data.join(','))
      this.$set(this.repaymentData[index], 'repayCondition', value)
      console.log('出发')
    },
    add () {
      if (this.repaymentData.length < 12) {
        this.repaymentData.push({ isAdd: true, businessId: this.$route.query.businessId, preRepayDate: '', preRepayAmount: '', contractRepaymentArr: '', repayCondition: '', contractRepayment: undefined })
      } else {
        this.warning('还款计划期数不能超过12期')
      }
    },
    // 提交
    submit () {
      if (this.repaymentData.length === 0) {
        this.warning('请完善还款计划')
        return false
      }
      let pass = true
      this.repaymentData.forEach((item) => {
        if (!item.preRepayDate || !item.preRepayAmount || !item.repayContractId || !item.repayCondition) {
          pass = false
        }
      })
      if (!pass) {
        this.warning('请完善还款计划')
        return false
      }
      let Amount = 0
      this.repaymentData.forEach((item) => {
        Amount = Amount + item.preRepayAmount * 1
      })

      if (this.financeAmount - Amount * 1 < 0) {
        this.warning('还款金额之和不能超过融资总额度')
        return false
      }
      this.$emit('repaymentPlanSubmit', this.repaymentData)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.top {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.addbtn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
  padding-right: 20px;
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
